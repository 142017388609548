@forward "./styles.base.module.scss";

body .button {
    background-color: rgba(255, 255, 255, 0.7);
    text-transform: unset;

    &:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }
}

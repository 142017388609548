.wrapper {
    position: relative;
}

.link {
    margin: 20px;
    position: absolute;
}

.top {
    top: 0;
}

.bottom {
    bottom: 0;
}

.right {
    right: 0;
}

.left {
    left: 0;
}

.centerX {
    left: 50%;
}

.centerY {
    top: 50%;
}

.transformX {
    transform: translateX(-50%);
    margin: 20px 0;
}

.transformY {
    transform: translateY(-50%);
    margin: 0 20px;
}

.transformXY {
    transform: translate(-50%, -50%);
    margin: 0;
}
